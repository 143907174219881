<template>
  <div>
    <r-missing-overview
      v-if="selectedItem==='r_missing_overview'"
      ref="r_missing_overview"
      :datasetP.sync="dataset"
      @is-computed="activeMenu"
      v-model="dataset"
      :analysisObj="getAnalysisForStep('r_missing_overview')"
    />
    <r-missing-remove
      v-else-if="selectedItem==='r_missing_showremove'"
      ref="r_missing_showremove"
      :datasetP.sync="dataset"
      :analysisObj="getAnalysisForStep('r_missing_showremove')"
    />
    <r-missing-impute
      v-else-if="selectedItem==='r_missing_impute'"
      ref="r_missing_impute"
      :datasetP="dataset"
      :analysisObj="getAnalysisForStep('r_missing_impute')"
    />
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'

export default {
  name: 'RMissing',
  components: {
    'r-missing-overview': () => import('@/components/ranalyses/RMissingOverview.vue'),
    'r-missing-remove': () => import('@/components/ranalyses/RMissingRemove.vue'),
    'r-missing-impute': () => import('@/components/ranalyses/RMissingImpute.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: 'Explore missing values',
          name: 'r_missing_overview',
          disabled: false
        },
        {
          title: 'Remove missing values',
          name: 'r_missing_showremove',
          disabled: true
        },
        {
          title: 'Impute missing values',
          name: 'r_missing_impute',
          disabled: true
        }
      ],
      dataset: null
    }
  },

  mounted () {
    if (this.analysisObj !== null) {
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.dataset = this.analysisObj.meta.func_args.datasetName
    }
  },
  methods: {
    activeMenu: function () {
      if (this.$refs.r_missing_overview.hasResults) {
        this.dataset = this.$refs.r_missing_overview.datasetName
        this.menuItems[1].disabled = false
        this.menuItems[2].disabled = false
      } else {
        this.menuItems[1].disabled = true
        this.menuItems[2].disabled = true
      }
    }
  }
}
</script>
